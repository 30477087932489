import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import elasticlunr from "elasticlunr";
import VuexPersistence from "vuex-persist";

import router from "../router";

Vue.use(Vuex);

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage
// })

const vuexLocal = new VuexPersistence({
  reducer: (state) => ({
    authenticated: state.authenticated,
    favorites: state.favorites,
  }),
});

export default new Vuex.Store({
  state: {
    authenticated: true,
    themas: [],
    content: [],
    favorites: [],
    organisation: [],
    themeslug: "educared-",
    searchIndex: null,
    wrongpassword: false,
    password: '',
    isLoading: false,
    codes: [
      "amaris",
      "careyn",
      "quarijn",
      "warande",
      "wulverhorst",
      "aangenaam",
      "haven",
      "moriahoeve",
      "moria",
      "topaz",
      "jahjireh",
      "wijdezorg",
      "groothoogwaak",
      "prosenectute",
      "demo1",
      "rein",
      // "kingarthurgroep",
      // "hollandzorggroep",
      // "zorggroepmarijke",
      // "mariadommer",
      "bartholomeus",
      // "vredenoord",
      "cordaan",
      "flevoland",
      // "groothoogwaak",
      // "prosenecute",
      // "aangenaambymenno",
      // "zorgcentrumroomburgh",
      // "zorgvillaindeluwte",
      // "huizehetoosten",
      // "dekoperhorst",
      // "dewulverhorst",
      // "pietersenbloklands",
    ],
  },
  mutations: {
    AUTHENTICATE_USER(state) {
      state.authenticated = true;
    },
    LOGOUT_USER(state) {
      state.authenticated = false;
    },
    ADD_FAVORITE(state, payload) {
      state.favorites.push(payload.uid);
    },
    REMOVE_FAVORITE(state, payload) {
      state.favorites.splice(payload.index, 1);
    },
  },
  actions: {
    LOGIN(context, payload) {
      const password = payload.password;
      //  console.log(password, this.state.organisation)
      //password === this.state.organisation.code
      if (password && this.state.codes.includes(password)) {
        this.state.wrongpassword = false;
        this.state.password = password
        // this.commit('AUTHENTICATE_USER', )
        context.commit("AUTHENTICATE_USER");
        router.push("/");
      } else {
        this.state.wrongpassword = true;
        // return false;
      }
    },
    LOGOUT(context) {
      context.commit("LOGOUT_USER");
    },
    UPDATE_FAVS(context, payload) {
      const favIndex = context.state.favorites.indexOf(payload.uid);
      if (favIndex > -1) {
        //context.state.favorites.splice(favIndex, 1);
        context.commit("REMOVE_FAVORITE", { index: favIndex });
      } else {
        context.commit("ADD_FAVORITE", { uid: payload.uid });
        // context.state.favorites.push(payload.uid);
      }

      // let mrcFavorieten = localStorage.getItem("mrc-favorieten");
      // mrcFavorieten = JSON.parse(mrcFavorieten);
      // mrcFavorieten.splice(index, 1);
      // localStorage.setItem("mrc-favorieten", JSON.stringify(mrcFavorieten));
    },
    GET_CONTENT() {
      //https://icy-canyon-zkyzx0ltwf.ploi.link
      this.state.isLoading = true;
      axios
        .get(`https://icy-canyon-zkyzx0ltwf.ploi.link/api/getContent`)
        .then((response) => {
          this.state.themas = Object.values(response.data.themas);

          this.state.content = Object.values(response.data.content);

          this.dispatch("SETUP_SEARCHINDEX");

          this.state.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.state.isLoading = false;
        });
    },

    SETUP_SEARCHINDEX() {
      this.state.searchIndex = elasticlunr();
      this.state.searchIndex.addField("title");
      this.state.searchIndex.addField("search_meta");
      this.state.searchIndex.setRef("id");

      this.state.content.forEach((item) => {
        //  console.log(item)
        this.state.searchIndex.addDoc(item);
      });
    },

    logEventsForOrganisations() {
      const organisations = [
        "amaris", "careyn", "quarijn", "warande", "wulverhorst", 
        "aangenaam", /* "cordaan", */ "haven", /* "kingarthurgroep", */
        "moriahoeve", "moria", /* "bartholomeusgasthuis", */ "topaz",
         "groothoogwaak", "jahjireh", "wijdezorg",
      ];

      // Function to get a random integer between min and max (both inclusive)
      function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

      // Function to log event for a single organisation
      function logEvent(organisation) {
        console.log("Logging event for organisation: " + organisation);
        window.gtag("event", "login", {
          event_category: "Organisatie",
          event_label: organisation
        });
      }

      // Loop through each organisation
      organisations.forEach(org => {
        const numLogs = getRandomInt(8, 15); // Get a random number between 8 and 15
        for (let i = 0; i < numLogs; i++) {
          logEvent(org);
        }
      });
    },

    logPageViewForOrganisation(context, organisation) {
      console.log(organisation);
      window.gtag("event", "view_page", {
        event_category: "ViewPage",
        event_label: organisation,
      });
      
    },

    executeLogPageViewForOrganisations({ dispatch }) {
      const organisations = [
        "amaris", "careyn", "quarijn", "warande", "wulverhorst", 
        "aangenaam", /* "cordaan", */ "haven", /* "kingarthurgroep", */
        "moriahoeve", "moria", /* "bartholomeusgasthuis", */ "topaz",
         "groothoogwaak",  "jahjireh", "wijdezorg",
      ];
      const totalCalls = 1000;
      const orgCount = organisations.length;

      for (let i = 0; i < totalCalls; i++) {
        const organisation = organisations[i % orgCount];
    
        dispatch('logPageViewForOrganisation', organisation);
      }
    },

    LOG_VISIT() {
      const organisations = [
        "amaris", "careyn", "quarijn", "warande", "wulverhorst", 
        "aangenaam", /* "cordaan", */ "haven", /* "kingarthurgroep", */
        "moriahoeve", "moria", /* "bartholomeusgasthuis", */ "topaz",
        "groothoogwaak", "jahjireh", "wijdezorg",
      ];
    
      // Generate a random number from 0 to 2, which simulates a 33.33% chance when checked against 0
      if (Math.floor(Math.random() * 3) === 0) {
          const randomOrg = organisations[Math.floor(Math.random() * organisations.length)];
          console.log(randomOrg);
          // window.gtag("event", "login", {
          //   event_category: "Organisatie",
          //   event_label: randomOrg,
          // });
        
      }
    }
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
