<template>
  <div class="card__container padding-xs">
    <router-link to="/vaardigheden"  class="no-underline">
      <div class="card__img-wrapper">
        <img
          loading="lazy"
          src="https://i.imgur.com/30uyCYf.jpeg"
          class="card-img-top"
        />
      </div>
      <div class="position-relative">
        <span class="card__type-indicator padding-x-sm padding-y-xs"
          ><i class="icon-thema"></i><strong> Onderwerp</strong></span
        >
      </div>

      <h5
        class="card-title card-title--styled margin-y-xs font-bold no-underline color-accent"
      >
        Vaardigheden<br /><small>   toegang tot 200 online trainingen </small>
      </h5>

    </router-link>
  </div>
</template>

<script>
import slugify from 'slugify';

export default {
  name: "ThemaCard",
  props: {
    thema: Object
  },
  mounted() {

  },
  computed: {
    slug(){
      return slugify(this.$props.thema.title, {lower: true});
    }
  }
};
</script>
