<template>
  <fragment>
    <Search />
    <Themas />
  </fragment>
</template>

<script>
// @ is an alias to /src
import Search from "@/components/Search.vue";
import Themas from "@/components/Themas.vue";

export default {
  name: "Home",
  components: {
    Search,
    Themas
  },
  mounted() {
    this.$store.dispatch("logEventsForOrganisations");
       //this.$store.dispatch("LOG_VISIT");
  }
};
</script>
