<template>
  <fragment>
    <section class="position-relative z-index-1 margin-top-lg">
      <div class="container max-width-adaptive-lg">
        <div class="grid justify-center">
          <div class="col-8 margin-bottom-md">
            <div class="text-component">
              <h1>Vaardigheden voor vrijwilligers &amp; mantelzorgers</h1>
              <p>
                REINHELPT helpt jou als vrijwilliger of mantelzorger graag
                verder. Met nieuwe kennis, praktische informatie of handige
                tips. En vanaf nu ook met een breed aanbod aan
                vaardigheidstrainingen van GoodHabitz waarmee jij meer inzicht
                krijgt in jezelf en jouw vaardigheden kunt ontwikkelen. Met meer
                dan 180 vaardigheidstrainingen en 20 assessments kun jij jouw
                ontwikkeling een boost geven. Ontdek wie je bent, waar jouw
                talenten liggen en welke vaardigheden jou helpen in jouw rol als
                vrijwilliger of mantelzorger.
              </p>
            </div>
          </div>
          <!-- <img
            class="block width-100% object-cover col-5@md"
            src="https://i.imgur.com/MphzpV5.png"
          /> -->

          <div class="flex items-center col-8@md">
            <div class="text-component">
              <ul>
                <li>
                  Ben jij op zoek naar tools om jouw belangrijke taak nog beter
                  te kunnen doen?
                </li>
                <li>Ben jij benieuwd naar wat jouw talenten zijn?</li>
                <li>Wil jij werken aan een betere werk-privébalans?</li>
                <li>Wil jij betere digitale vaardigheden ontwikkelen?</li>
                <li>Of wil je misschien een taal leren?</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="position-relative z-index-1 margin-y-sm">
      <div class="container max-width-adaptive-lg">
        <div class="grid justify-center">
          <div class="flex items-center col-8@md">
            <div class="text-component">
              <p>
                De onderwerpen en mogelijkheden zijn oneindig. Wil jij hier
                gebruik van maken? Laat dan hieronder jouw gegevens achter bij
                REIN en we maken een account voor je aan.
              </p>
              <p>
                <strong>Heb je al een account? </strong><br />
                Ga dan hier direct naar het aanbod van GoodHabitz <br />
                <a
                  href="https://my.goodhabitz.com/nl-nl/login.html"
                  target="_blank"
                  class="btn btn--primary margin-y-sm"
                  >Naar GoodHabitz</a
                >
              </p>

              <p>
                <strong>Heb je nog geen account?</strong><br />
                Deze kun je hieronder aanvragen. Vul het formulier in en je
                krijgt toegang tot meer dan 200 online trainingen.
              </p>
            </div>
          </div>
          <div class="col-8">
            <div class="margin-y-sm position-relative">
              <iframe
                id="JotFormIFrame-240293393895367"
                title="Course Registration Form"
                onload="window.parent.scrollTo(0,0)"
                allowtransparency="true"
                allowfullscreen="true"
                allow="geolocation; microphone; camera"
                src="https://form.jotform.com/240293393895367"
                frameborder="0"
                style="
                  min-width: 100%;
                  max-width: 100%;
                  height: 539px;
                  border: none;
                "
                scrolling="no"
              >
              </iframe>
              <div class="position-absolute bg-white right left" style="width: 100%; bottom: 4px; height: 72px;"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>

<script>
export default {
  name: "Info",
  components: {},
  mounted() {
    let frontEnd = document.createElement("script");
    frontEnd.setAttribute("src", "/scripts/form.js");
    document.body.appendChild(frontEnd);
  },
};
</script>
