<template>
  <div
    class="bg--fullscreen"
    style="
      background-image: url(https://source.unsplash.com/VMGAbeeJTKo/1600x1280);
    "
  >
    <div class="container padding-y-md max-width-lg">
      <div class="grid gap-xs justify-center padding-top-xxl">
        <div class="col-12 col-5@md">
          <div class="card__container padding-xl">
            <div class="logo-container padding-xs margin-bottom-md radius-md">
              <figure>
                <img
                  class="margin-x-auto block width-60% animation--off"
                  src="http://icy-canyon-zkyzx0ltwf.ploi.link/assets/main/reainhelpt.png"
                />
              </figure>
            </div>

            <div class="text-component margin-bottom-md">
              <h2 class="margin-bottom-md">Inloggen</h2>

              <p class="color-contrast-medium">
                Voer de organisatiecode in om verder te gaan
              </p>
            </div>

            <div class="grid gap-md justify-center">
              <div class="col-12">
                <!-- <select
                class="form-control width-100% margin-bottom-sm"
                  id="domainSelect"
                  v-model="selectedDomain"
                  @change="redirectToDomain"
                >
                  <option value="">-- Kies uw organisatie --</option>
                  <option value="https://reinhelpt.nl">Stichting Rein</option>
                  <option value="https://amaris.reinhelpt.nl">Amaris</option>
                  <option value="https://quarijn.reinhelpt.nl">Quarijn</option>
                  <option value="https://warande.reinhelpt.nl">Warande</option>
                  <option value="https://moriahoeve.reinhelpt.nl">Moriahoeve</option>
                  <option value="https://topaz.reinhelpt.nl">Topaz</option>
                  <option value="https://wijdezorg.reinhelpt.nl">Jah Jireh</option>
                  
                </select> -->
                <form @submit.prevent="login()">
                  <div
                    class="form-control-wrapper form-control-wrapper--icon-left margin-x-auto margin-bottom-sm"
                  >
                    <input
                      class="form-control width-100%"
                      type="text"
                      name="iconInputX"
                      id="iconInputX"
                      placeholder="A24B5DJ"
                      @keyup="checkCode()"
                      v-model="password"
                    />
                    <svg aria-hidden="true" class="icon" viewBox="0 0 24 24">
                      <g
                        stroke-linecap="square"
                        stroke-linejoin="miter"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-miterlimit="10"
                      >
                        <path
                          d="M1,23 c0-5.523,4.477-10,10-10h2c5.523,0,10,4.477,10,10"
                        ></path>
                        <circle cx="12" cy="7" r="6"></circle>
                      </g>
                    </svg>
                  </div>
                  <span
                    v-if="$store.state.wrongpassword"
                    class="color-error margin-bottom-sm text-sm"
                    >*De ingevoerde code is onjuist</span
                  >
                  <p>
                    <button
                      type="submit"
                      class="btn btn--primary btn--md width-100% margin-y-xs"
                    >
                      Inloggen &rarr;
                    </button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Login",
  components: {},
  data() {
    return {
      password: "",
      selectedDomain: "",
    };
  },
  mounted() {
    if (this.$store.state.authenticated) {
   
      this.$router.push("/");
    }
  },
  methods: {
    redirectToDomain() {
      if (this.selectedDomain) {
        window.location.href = this.selectedDomain;
      }
    },
    login() {
      window.gtag("event", "login", {
        event_category: "Organisatie",
        event_label: this.password
      });
      
      this.$store.dispatch("LOGIN", { password: this.password });
    },
    checkCode() {
      this.$store.dispatch("CHECKCODE", { password: this.password });
    },
  },
};
</script>
