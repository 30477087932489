<template>
  <footer
    class="footer-v4 padding-y-lg"
    :data-theme="`${$store.state.themeslug}dark`"
  >
    <div class="container max-width-xl">
      <nav class="footer-v4__nav">
        <ul class="footer-v4__nav-list">

          <li
            class="footer-v4__nav-item"
            :key="thema.id"
            v-for="thema in themas"
          >
            <router-link
              :to="{
                name: 'Themaoverzicht',
                params: { slug: slugger(thema.title), title: thema.title },
              }"
              class="no-underline"
              >{{ thema.title }}</router-link
            >
          </li>
                    <li   class="footer-v4__nav-item">
           <router-link
              to="/vaardigheden"
              class="no-underline"
              >Vaardigheden</router-link
            >
          </li>
        </ul>
      </nav>

      <div class="container-fluid text-center margin-bottom-md" style="mix-blend-mode: darken;">
            <img src="https://www.amaris.nl/img/logo.svg" height="40" class="margin-right-lg" />
            <img src="https://i.imgur.com/lGDHVGV.png" height="40" class="margin-right-lg" />
            <img src="https://i.imgur.com/qPEbMh4.png" height="40" class="margin-right-lg" />
            <img src="https://topaz.nl/assets/img/logo-topaz.svg" height="30" class="margin-right-lg" />
            <img src="https://moria.nl/wp-content/themes/moria/src/assets/svg/main_logo.svg" height="32" class="margin-right-lg" /><br />
            <img src="https://i.imgur.com/pNXEcSS.png" height="30" class="margin-right-lg" />
             <img src="https://www.pro-senectute.nl/templates/os-basetemplate/images/logo_pro-senectute.svg" height="50" class="margin-right-lg" />
            <img src="https://www.careyn.nl/images/careyn-logo.svg" height="60" class="margin-right-lg" />
            <img src="https://www.jouwzorgbaan.nl/clientdata/images/organisaties/logos/width/320/logo-quarijn.png" height="40" class="margin-right-lg" />
            <img src="https://www.warandeweb.nl/wp-content/uploads/2018/06/WARANDE_Corporate_Logo_retina.png.webp" height="40" class="margin-right-lg" />
            <img src="https://www.groothoogwaak.nl/wp-content/themes/Groothoogwaak/assets/dist/images/groot-hoogwaak-logo.abf5d4a3.svg" height="40" class="margin-right-lg" />
            <img src="https://aangenaambymenno.nl/wp-content/uploads/2020/08/Aangenaam-logo.png" height="40" class="margin-right-lg" />
            <img src="https://www.wulverhorst.nl/serverspecific/default/images/Template/logo.png" height="40" class="margin-right-lg margin-top-md" />
            <img src="https://www.cordaan.nl/images/cordaan-logo.svg" height="40" class="margin-right-lg margin-top-md" />
            <img src="https://bartholomeusgasthuis.nl/wp-content/themes/bartholomeus/assets/img/logo.png" height="50" class="margin-right-lg margin-top-md" />
            <img src="https://www.coloriet.nl/wp-content/uploads/2021/07/logo-coloriet.svg" height="24" class="margin-right-lg margin-top-md" />
            <img src="https://www.welzijnlelystad.nl/wp-extensions/themes//welzijnlelystad2021-theme/static/img/welzijnlelystad.svg" height="30" class="margin-right-lg margin-top-md" />
            <img src="https://i.imgur.com/oMRYu2S.png" height="50" class="margin-right-lg margin-top-md" />

      </div>

      <div class="footer-v4__logo">
        <router-link to="/">
          <h1 class="opacity-60%"><BuddieLogo /></h1>
        </router-link>
        <div class="text-component text-center">
          <p class="text-sm text-center">
            <a href="https://ikbenrein.info">www.ikbenrein.info</a>
          </p>
           <p class="text-sm text-center">
            <a href="/assets/privacyverklaring.pdf">Privacyverklaring</a>
          </p>
        </div>
        <!-- <figure>
            <img
              class="margin-bottom-sm margin-x-auto block animation--off"
              width="160"
              height="auto"
              :src="$store.state.organisation.logo"
            />
          </figure> -->
      </div>

      <p class="footer-v4__print">
        REINHELPT is een initiatief van Stichting REIN in samenwerking met
        Educared en Samen beter Thuis
      </p>

      <div class="footer-v4__socials">
        <a href="https://www.facebook.com/stichtingrein" target="_blank">
<svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100px" height="100px">    <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"/></svg>
        </a>
        <a href="https://www.instagram.com/stichtingrein/" target="_blank">
          <svg
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 24 24"
            id="instagram"
          >
            <path
              d="M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z"
            ></path>
          </svg>
        </a>

        <a
          href="https://www.linkedin.com/company/stichtingrein/"
          target="_blank"
        >
          <svg
            class="icon"
            fill="#000000"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 310 310"
            xml:space="preserve"
          >
            <g id="XMLID_801_">
              <path
                id="XMLID_802_"
                d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
		C77.16,101.969,74.922,99.73,72.16,99.73z"
              />
              <path
                id="XMLID_803_"
                d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
		c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
              />
              <path
                id="XMLID_804_"
                d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
		c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
		c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
		C310,145.43,300.549,94.761,230.454,94.761z"
              />
            </g>
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import slugify from "slugify";
import BuddieLogo from "@/components/BuddieLogo";
import orderBy from "lodash/orderBy";

export default {
  name: "Footer",
  components: {
    BuddieLogo,
  },
  methods: {
    slugger(title) {
      return slugify(title, { lower: true });
    },
  },
  computed: {
    themas() {
      return orderBy(this.$store.state.themas, "positie");
    },
  },
};
</script>
