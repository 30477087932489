<template>
  <fragment>
    <section :data-theme="`${$store.state.themeslug}soft`" class="padding-y-lg">
      <div class="container max-width-lg">
        <div class="grid grid-gap-sm">
          <div class="col-12 margin-bottom-md">
            <router-link
              :to="{
                name: 'Themaoverzicht',
                params: { slug: themaslug, title: thema.title },
              }"
              class="no-underline font-bold"
            >
              <svg
                version="1.1"
                class="icon align-middle margin-right-xs"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 492 492"
                style="enable-background: new 0 0 492 492"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124
			c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844
			L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412
			c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008
			c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788
			C492,219.198,479.172,207.418,464.344,207.418z"
                    />
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
              Terug</router-link
            >
          </div>
          <div class="col-12 col-8@md">
            <div class="card__container padding-md">
              <div class="text-component margin-y-sm">
                <router-link
                  :to="{
                    name: 'Themaoverzicht',
                    params: { slug: themaslug, title: thema.title },
                  }"
                  class="no-underline"
                  ><span class="badge badge--primary-light"
                    ><i class="icon-thema margin-right-xs"></i>
                    {{ thema.title }}</span
                  ></router-link
                >
                <h4 class="color-primary">{{ content.title }}</h4>
              </div>

              <figure
                v-if="content.image && content.type !== 'document'"
                class="position-relative cursor-pointer"
                :aria-controls="embedUrl"
                :data-url="embedUrl"
              >
                <div class="play-overlay">
                  <a
                    class="play-overlay__button"
                    :aria-controls="embedUrl"
                    :data-url="embedUrl"
                  >
                    <svg
                      version="1.1"
                      v-if="content.type === 'document'"
                      class="icon"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style="enable-background: new 0 0 512 512"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M495.914,189.604c-18.965-15.914-47.343-13.424-63.228,5.506l-42.695,50.523V99.129c0-12.279-5.106-24.17-14.008-32.625
			l-56.978-54.125C310.606,4.4,299.6,0.005,288.015,0.005H44.999C20.187,0.005,0,20.192,0,45.004v421.991
			c0,24.812,20.187,44.999,44.999,44.999h299.994c24.812,0,44.999-20.187,44.999-44.999v-81.961
			C391.835,382.851,501.116,253.407,501.46,253C517.447,233.948,514.969,205.592,495.914,189.604z M299.994,35.695
			c60.013,57.008,55.751,52.841,56.876,54.309h-56.876V35.695z M359.992,466.995c0,8.271-6.729,15-15,15H44.999
			c-8.271,0-15-6.729-15-15V45.004c0-8.271,6.729-15,15-15h224.995v74.998c0,8.284,6.716,15,15,15h74.998v161.129
			c-6.443,7.624-58.178,68.843-63.77,75.46c-4.467,5.324-7.682,11.711-9.296,18.47l-13.94,58.356
			c-1.319,5.526,0.596,11.324,4.948,14.976c4.356,3.656,10.399,4.529,15.607,2.272l55.05-23.862
			c4.133-1.792,7.988-4.226,11.401-7.151V466.995z M324.315,369.827l22.978,19.28l-5.11,6.052c-1.487,1.774-3.398,3.199-5.523,4.12
			l-27.524,11.931l6.971-29.178c0.538-2.253,1.609-4.382,3.064-6.116L324.315,369.827z M366.644,366.184l-22.967-19.271
			c2.33-2.757,77.698-91.943,82.91-98.11l22.919,19.231L366.644,366.184z M478.509,233.682l-9.649,11.43l-22.908-19.222
			l9.682-11.457c5.289-6.303,14.71-7.125,20.997-1.849C483.043,217.963,483.75,227.436,478.509,233.682z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M224.995,90.003H74.998c-8.284,0-15,6.716-15,15s6.716,15,15,15h149.997c8.284,0,15-6.716,15-15
			S233.279,90.003,224.995,90.003z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M314.993,181.001H74.998c-8.284,0-15,6.716-15,15s6.716,15,15,15h239.995c8.284,0,15-6.716,15-15
			S323.277,181.001,314.993,181.001z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M314.993,271H74.998c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h239.995c8.284,0,15-6.716,15-15
			C329.993,277.715,323.277,271,314.993,271z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M224.995,360.998H74.998c-8.284,0-15,6.716-15,15s6.716,15,15,15h149.997c8.284,0,15-6.716,15-15
			S233.279,360.998,224.995,360.998z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>

                    <svg
                      v-if="content.type !== 'document'"
                      version="1.1"
                      class="icon"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="163.861px"
                      height="163.861px"
                      viewBox="0 0 163.861 163.861"
                      style="enable-background: new 0 0 163.861 163.861"
                      xml:space="preserve"
                    >
                      <g>
                        <path
                          d="M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275
		c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z"
                        />
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </a>
                  <span
                    :aria-controls="embedUrl"
                    :data-url="embedUrl"
                    class="play-overlay__text color-white"
                    >Bekijk {{ content.type }}</span
                  >
                </div>
                <img
                  class="block width-100% animation--off margin-y-sm radius-md"
                  :src="content.image"
                />
              </figure>

              <figure
                v-else
                class="position-relative cursor-pointer"
                :href="embedUrl"
                target="_blank"
              >
                <div class="play-overlay">
                  <a
                    class="play-overlay__button cursor-pointer"
                    :href="embedUrl"
                    target="_blank"
                  >
                    <svg
                      version="1.1"
                      class="icon"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style="enable-background: new 0 0 512 512"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M495.914,189.604c-18.965-15.914-47.343-13.424-63.228,5.506l-42.695,50.523V99.129c0-12.279-5.106-24.17-14.008-32.625
			l-56.978-54.125C310.606,4.4,299.6,0.005,288.015,0.005H44.999C20.187,0.005,0,20.192,0,45.004v421.991
			c0,24.812,20.187,44.999,44.999,44.999h299.994c24.812,0,44.999-20.187,44.999-44.999v-81.961
			C391.835,382.851,501.116,253.407,501.46,253C517.447,233.948,514.969,205.592,495.914,189.604z M299.994,35.695
			c60.013,57.008,55.751,52.841,56.876,54.309h-56.876V35.695z M359.992,466.995c0,8.271-6.729,15-15,15H44.999
			c-8.271,0-15-6.729-15-15V45.004c0-8.271,6.729-15,15-15h224.995v74.998c0,8.284,6.716,15,15,15h74.998v161.129
			c-6.443,7.624-58.178,68.843-63.77,75.46c-4.467,5.324-7.682,11.711-9.296,18.47l-13.94,58.356
			c-1.319,5.526,0.596,11.324,4.948,14.976c4.356,3.656,10.399,4.529,15.607,2.272l55.05-23.862
			c4.133-1.792,7.988-4.226,11.401-7.151V466.995z M324.315,369.827l22.978,19.28l-5.11,6.052c-1.487,1.774-3.398,3.199-5.523,4.12
			l-27.524,11.931l6.971-29.178c0.538-2.253,1.609-4.382,3.064-6.116L324.315,369.827z M366.644,366.184l-22.967-19.271
			c2.33-2.757,77.698-91.943,82.91-98.11l22.919,19.231L366.644,366.184z M478.509,233.682l-9.649,11.43l-22.908-19.222
			l9.682-11.457c5.289-6.303,14.71-7.125,20.997-1.849C483.043,217.963,483.75,227.436,478.509,233.682z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M224.995,90.003H74.998c-8.284,0-15,6.716-15,15s6.716,15,15,15h149.997c8.284,0,15-6.716,15-15
			S233.279,90.003,224.995,90.003z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M314.993,181.001H74.998c-8.284,0-15,6.716-15,15s6.716,15,15,15h239.995c8.284,0,15-6.716,15-15
			S323.277,181.001,314.993,181.001z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M314.993,271H74.998c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h239.995c8.284,0,15-6.716,15-15
			C329.993,277.715,323.277,271,314.993,271z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M224.995,360.998H74.998c-8.284,0-15,6.716-15,15s6.716,15,15,15h149.997c8.284,0,15-6.716,15-15
			S233.279,360.998,224.995,360.998z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </a>
                  <span class="play-overlay__text color-white"
                    >Bekijk {{ content.type }}</span
                  >
                </div>
                <img
                  class="block width-100% animation--off margin-y-sm radius-md"
                  :src="content.image"
                />
              </figure>

              <div
                class="text-component margin-top-md cms--content"
                v-html="content.description"
              ></div>

              <!-- <div class="text-component margin-top-md" v-if="downloads">
                <h5 class="margin-bottom-sm font-bold">Downloads</h5>
                <a
                  :key="download"
                  v-for="download in downloads"
                  class="btn btn--accent"
                >
                  <i class="icon-document margin-right-xs"></i>
                  {{ getFilename(download) }}
                </a>
              </div> -->
            </div>
          </div>
          <div class="col-12 col-4@md">
            <div class="card__container padding-md">
              <button
                class="btn"
                :class="isFav ? 'btn--accent' : 'btn--primary'"
                @click.stop.prevent="toggleFav()"
              >
                <i v-if="!isFav" class="icon-star margin-right-xs"></i>
                <i
                  v-if="isFav"
                  class="icon-star-fill color-white margin-right-xs"
                ></i>
                {{
                  isFav
                    ? "Toegevoegd aan favorieten"
                    : "Toevoegen aan favorieten"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal :modalId="embedUrl" />
    </section>
  </fragment>
</template>

<script>
// @ is an alias to /src
import Modal from "@/components/Modal.vue";
import slugify from "slugify";

export default {
  name: "DetailView",
  components: {
    Modal,
  },
  mounted() {
    window.gtag("event", "loadPage", {
      app_name: "ReinHelpt",
      item: this.content.title,
    });
  },
  methods: {
    youtubeParser(url) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
    vimeoParser(url) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
    goBack() {
      this.$router.go(-1);
    },

    getFilename(url) {
      return url.substring(url.lastIndexOf("/") + 1);
    },
    toggleFav() {
      this.$store.dispatch("UPDATE_FAVS", { uid: this.content.id });
    },
  },
  computed: {
    isFav() {
      return this.$store.state.favorites.indexOf(this.content.id) > -1;
    },
    slug() {
      return this.$route.params.slug;
    },
    content() {
      return this.$route.params.content;
    },
    embedUrl() {
      //if this.type is video

      if (this.content.type === "video") {
        if (this.content.embed_url.includes("vimeo")) {
          return `${this.content.embed_url}&progress_bar=true`;
        } else {
          const videoID = this.youtubeParser(this.content.embed_url);
          // console.log('url', videoID);
          return `https://www.youtube-nocookie.com/embed/${videoID}?autoplay=1&controls=1&start=${this.content.video_start_at}&enablejsapi=1&rel=0&showinfo=0`;
        }
      } else if (this.content.type === "document") {
        // console.log('t', this.content.type)
        // return this.content.embed_url;

        return this.content.embed_url;
      } else {
        return this.content.embed_url;
      }
    },
    thema() {
      return this.$store.state.themas.find((thema) => {
        return thema.id.split("::")[1] === this.content.thema;
      });
    },
    themaslug() {
      return slugify(this.thema?.title, { lower: true });
    },
    downloads() {
      if (!this.content.type === "document") return;

      return this.content.embed_url;
    },
  },
};
</script>
