<template>
  <header
    :data-theme="`${$store.state.themeslug}default`"
    class="header-v2 js-header-v2 shadow-sm"
    data-animation="on"
    data-animation-offset="400"
  >
    <div class="header-v2__wrapper">
      <div class="header-v2__container container max-width-lg">
        <div class="header-v2__logo" v-if="$route.name !== 'Info'">
          <router-link to="/">
            <figure>
              <img
                class="margin-x-auto block animation--off"
                width="auto"
                height="96"
                src="http://icy-canyon-zkyzx0ltwf.ploi.link/assets/main/reainhelpt.png"
              />
            </figure>
          </router-link>
        </div>
        <div class="header-v2__logo" v-if="$route.name == 'Info'">
          <img
            class="margin-x-auto block animation--off"
            width="auto"
            height="96"
            src="https://i.imgur.com/hbNZZ4T.png"
          />
        </div>
        <div>
          <router-link
            v-if="$route.name == 'Info'"
            to="/login"
            class="btn btn--primary"
            >Inloggen
          </router-link>
          <router-link
            v-if="$route.name !== 'Info'"
            to="/favorieten"
            class="btn btn--primary margin-right-sm"
            >Favorieten
            <span
              v-if="hasFavorites"
              class="counter counter--critical margin-left-xxs"
              >{{ favCount }} <i class="sr-only">Notifications</i></span
            ></router-link
          >

          <button
            @click="logout"
            v-if="$route.name !== 'Info'"
            class="btn btn--secondary"
          >
            Uitloggen
          </button>
        </div>

        <!-- <button
          class="header-v2__nav-control flex hide@md reset anim-menu-btn js-anim-menu-btn js-tab-focus"
          aria-label="Toggle menu"
        >
          <i
            class="anim-menu-btn__icon anim-menu-btn__icon--close"
            aria-hidden="true"
          ></i>
        </button> -->

        <!-- <nav class="nav-v2" role="navigation">
          <ul class="nav-v2__list nav-v2__list--main"></ul>

          <ul class="nav-v2__list nav-v2__list--main">
            <li
              class="nav-v2__item nav-v2__item--main"
              v-if="$route.name !== 'Info'"
            >
              <router-link to="/favorieten" class="btn btn--primary"
                >Favorieten
                <span
                  v-if="hasFavorites"
                  class="counter counter--critical margin-left-xxs"
                  >{{ favCount }} <i class="sr-only">Notifications</i></span
                ></router-link
              >
            </li>

                        <li
              class="nav-v2__item nav-v2__item--main"
              v-if="$route.name !== 'Info'"
            >
              <button @click="logout" class="btn btn--secondary"
                >Uitloggen
              </button>
            </li>

            <li
              class="nav-v2__item nav-v2__item--main"
              v-if="$route.name == 'Info'"
            >
              <router-link to="/login" class="btn btn--primary"
                >Inloggen
              </router-link>
            </li>

          </ul>
        </nav> -->
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Nav",

  mounted() {
    let frontEnd = document.createElement("script");
    frontEnd.setAttribute("src", "/scripts/nav.js");
    document.body.appendChild(frontEnd);
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT");
      this.$router.push("/info");
    },
  },
  computed: {
    hasFavorites() {
      return this.$store.state.favorites.length > 0;
    },
    favCount() {
      return this.$store.state.favorites.length;
    },
  },
};
</script>
